/*!
 *  - newsIr.js
 * @generated Tue Nov 19 2019 09:29:09 GMT+0900 (東京 (標準時))
 */
'use strict';

(function ($, DIC) {
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';
	var lang = location.pathname.split('/')[1];
	var addHtml = void 0,
	    itemList = void 0,
	    mpoketLang = void 0,
	    margeList = void 0;
	if (lang === 'ja') {
		mpoketLang = 'all';
	} else {
		mpoketLang = 'enall';
	}

	// ＜共通＞JSON取得 ---------------------------------------------------
	// トップページニュース・ニュースティッカー部分
	var getJSONcmsTop = function getJSONcmsTop() {
		return $.getJSON('/' + lang + '/ajax/index.json');
	};
	// ニューストップ・ニュースIR部分
	var getJSONcmsNews = function getJSONcmsNews(_year) {
		return $.getJSON('/' + lang + '/ajax/news/' + _year + '/index.json');
	};
	// irトップ（お知らせ／適時開示情報・最新決算情報）部分
	var getJSONcmsIr = function getJSONcmsIr() {
		return $.getJSON('/' + lang + '/ajax/ir/index.json');
	};
	// マジカルポケット、すべてのニュース
	var getJSONmpocket = function getJSONmpocket() {
		return $.ajax({
			type: 'GET',
			url: 'https://xml.irpocket.com/4631/JS/release-' + mpoketLang + '-all.js',
			dataType: 'jsonp',
			jsonp: 'callback',
			jsonpCallback: 'ir20handler'
		});
	};
	// マジカルポケット、年別取得
	var getJSONmpocketNews = function getJSONmpocketNews(_year) {
		return $.ajax({
			type: 'GET',
			url: 'https://xml.irpocket.com/4631/JS/release-' + mpoketLang + '-12m-' + _year + '01.js',
			dataType: 'jsonp',
			jsonp: 'callback',
			jsonpCallback: 'ir20handler'
		});
	};
	// ＜共通＞各jsonを整型→マージ---------------------------------------------------
	var cmsDataMolding = function cmsDataMolding(_cms) {
		var cmsData = _cms.rt_bn_news_list;
		// cmsニュースの配列作成
		var cmsDataList = [];
		for (var i = 0; i < cmsData.length; i++) {
			// リンクタイプだし分け
			var cmshref = '';
			var cmstarget = '';
			var cmslinkNoneClass = '';
			if (cmsData[i].cf_n_type === 1 || cmsData[i].cf_n_type === 4 || cmsData[i].cf_n_type === 6 || cmsData[i].cf_n_type === 7) {
				cmshref = cmsData[i].rt_cf_n_href.href;
			} else if (cmsData[i].cf_n_type === 2 || cmsData[i].cf_n_type === 3) {
				cmshref = cmsData[i].rt_cf_n_href.href;
				cmstarget = '_blank';
			} else if (cmsData[i].cf_n_type === 5) {
				cmshref = '';
				cmslinkNoneClass = 'mod-news-item-linkNone';
			}
			// ニュースリリースフラグだし分け
			var cmsflag = '';
			if (cmsData[i].rt_cf_n_newsrelease_flag) {
				if (lang === 'ja') {
					cmsflag = '<span class="mod-news-item-label">ニュースリリース</span>';
				} else if (lang === 'en') {
					cmsflag = '<span class="mod-news-item-label">News Release</span>';
				}
			}
			// 画像だし分け(画像がないものには、それぞれのカテゴリの画像を割り当て)
			var cmsimg = '';
			if (cmsData[i].rt_cf_n_thum_img) {
				cmsimg = cmsData[i].rt_cf_n_thum_img.src;
			} else {
				if (cmsData[i].cf_n_category.cf_nc_code === 'products') {
					cmsimg = '/images/news/products.jpg';
				} else if (cmsData[i].cf_n_category.cf_nc_code === 'ir') {
					cmsimg = '/images/news/ir.jpg';
				} else if (cmsData[i].cf_n_category.cf_nc_code === 'csr') {
					cmsimg = '/images/news/csr.jpg';
				} else if (cmsData[i].cf_n_category.cf_nc_code === 'event') {
					cmsimg = '/images/news/event.jpg';
				} else if (cmsData[i].cf_n_category.cf_nc_code === 'other') {
					cmsimg = '/images/news/other.jpg';
				}
			}
			// 国別カテゴリー名だし分け
			var cmsCategoryName = '';
			if (lang === 'ja') {
				cmsCategoryName = cmsData[i].cf_n_category.cf_nc_name_ja;
			} else {
				cmsCategoryName = cmsData[i].cf_n_category.cf_nc_name_en;
			}
			// 年・日時を取得(JSTに変換)
			var cmsdate = new Date(cmsData[i].cf_n_date);

			cmsDataList[i] = {
				categoryCord: cmsData[i].cf_n_category.cf_nc_code,
				date: cmsdate,
				title: cmsData[i].cf_n_title,
				categoryName: cmsCategoryName,
				flag: cmsflag,
				flag2: '',
				img: cmsimg,
				href: cmshref,
				target: cmstarget,
				linkNoneClass: cmslinkNoneClass
			};
		}
		return cmsDataList;
	};
	var mpocketDataMolding = function mpocketDataMolding(_mpocket) {
		var mpocketData = _mpocket.item;
		console.log('mpocketData', mpocketData);
		// mpocketニュースの配列作成
		var mpocketDataList = [];
		for (var k = 0; k < mpocketData.length; k++) {
			// 国別カテゴリー名だし分け
			var mpocketCategoryName = '';
			if (lang === 'ja') {
				mpocketCategoryName = '経営・IR';
			} else {
				mpocketCategoryName = 'Management & IR';
			}
			// 年・日時を取得
			var mpocketdate = new Date(mpocketData[k].published);
			// フラグだし分け
			var mpocketflag1 = '';
			var mpocketflag2 = '';
			if (lang === 'ja' && !(mpocketData[k].category_name === '有価証券報告書')) {
				mpocketflag1 = '<span class="mod-news-item-label mod-news-item-label-timelyDisclosure">適時開示</span>';
				mpocketflag2 = '<span class="mod-newsList-label-min">適時開示</span>';
			} else if (lang === 'en') {
				mpocketflag1 = '<span class="mod-news-item-label mod-news-item-label-timelyDisclosure">TSE Filings</span>';
				mpocketflag2 = '<span class="mod-newsList-label-min">TSE Filings</span>';
			}

			mpocketDataList[k] = {
				categoryCord: 'ir',
				date: mpocketdate,
				title: mpocketData[k].title,
				categoryName: mpocketCategoryName,
				flag: mpocketflag1,
				flag2: mpocketflag2,
				img: '/images/news/ir_tse.jpg',
				href: mpocketData[k].link,
				target: '_blank',
				linkNoneClass: ''
			};
		}
		return mpocketDataList;
	};
	var itemListMarge = function itemListMarge($cms, $mpoket) {
		// 配列をマージ
		itemList = cmsDataMolding($cms).concat(mpocketDataMolding($mpoket));
		// 配列を日付順でソート
		itemList.sort(function ($itemlista, $itemlistb) {
			return $itemlista.date < $itemlistb.date ? 1 : -1;
		});
		return itemList;
	};
	var itemListDateChange = function itemListDateChange($list) {
		itemList = $list;
		// 日付を日英別で変換
		var itemListdate = void 0,
		    itemListyear = void 0,
		    itemListmonth = void 0,
		    itemListmonthEn = void 0,
		    itemListday = void 0;
		for (var j = 0; j < itemList.length; j++) {
			itemListdate = new Date(itemList[j].date);
			itemListyear = itemListdate.getFullYear();
			itemListmonth = itemListdate.getMonth() + 1;
			itemListday = itemListdate.getDate();
			if (lang === 'en') {
				switch (itemListmonth) {
					case 1:
						itemListmonthEn = 'Jan.';break;
					case 2:
						itemListmonthEn = 'Feb.';break;
					case 3:
						itemListmonthEn = 'Mar.';break;
					case 4:
						itemListmonthEn = 'Apr.';break;
					case 5:
						itemListmonthEn = 'May';break;
					case 6:
						itemListmonthEn = 'Jun.';break;
					case 7:
						itemListmonthEn = 'Jul.';break;
					case 8:
						itemListmonthEn = 'Aug.';break;
					case 9:
						itemListmonthEn = 'Sep.';break;
					case 10:
						itemListmonthEn = 'Oct.';break;
					case 11:
						itemListmonthEn = 'Nov.';break;
					case 12:
						itemListmonthEn = 'Dec.';break;
					default:
						itemListmonthEn = 1;
				}
				itemList[j].date = itemListmonthEn + ' ' + itemListday + ', ' + itemListyear;
			} else {
				itemList[j].date = itemListyear + '年' + itemListmonth + '月' + itemListday + '日';
			}
			if ($('.js-ir-news-listLink').length) {
				$('.js-ir-news-listLink').attr('href', '/' + lang + '/news/' + itemListyear + '/ir/');
			}
		}
		return itemList;
	};
	// ＜共通＞書き出し用html------------------------------------------------
	var boxListHtml = function boxListHtml($itemList, $maxNum, $addArea) {
		for (var l = 0; l < $maxNum; l++) {
			addHtml = '<li class="pure-u-1-4 pure-u-lg-1 space-u-40-child">' + '<a href="' + $itemList[l].href + '" class="mod-news-item ' + $itemList[l].linkNoneClass + '" target="' + $itemList[l].target + '">' + '<figure class="mod-news-item-img">' + '<img src="' + $itemList[l].img + '" class="width-u-100">' + '<figcaption class="mod-label ' + $itemList[l].categoryCord + '">' + $itemList[l].categoryName + '</figcaption>' + '</figure>' + '<div class="mod-news-item-text">' + '<span class="mod-news-item-head">' + '<span class="mod-news-item-day"><i class="icon icon-time"></i><span class="">' + $itemList[l].date + '</span></span>' + $itemList[l].flag + '</span><span class="mod-news-item-title">' + $itemList[l].title + '</span></div></a></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	var tickerHtml = function tickerHtml($itemList, $maxNum, $addArea) {
		for (var l = 0; l < $maxNum; l++) {
			addHtml = '<li class="index-ticker-item">' + '<a href="' + $itemList[l].href + '" class="mod-a" target="' + $itemList[l].target + '">' + '<p class="index-ticker-title">' + '<span class="index-ticker-title-text">' + $itemList[l].title + '</span>' + '</p></a></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	var textListHtml = function textListHtml($itemList, $maxNum, $addArea) {
		for (var l = 0; l < $maxNum; l++) {
			addHtml = '<li class="mod-newsList">' + '<span class="mod-newsList-day">' + '<span><i class="icon icon-time"></i><span>' + $itemList[l].date + '</span></span></span>' + '<span class="mod-newsList-label"><span class="mod-label ' + $itemList[l].categoryCord + '">' + $itemList[l].categoryName + '</span></span>' + '<span class="mod-newsList-title"><a href="' + $itemList[l].href + '" class="' + $itemList[l].linkNoneClass + '" target="' + $itemList[l].target + '">' + $itemList[l].title + $itemList[l].flag2 + '</a></span></li>';
			$(addHtml).appendTo($addArea);
		}
	};
	// <共通>---------------------------------------------------------------
	// トップページニュース・ニュースティッカー部分
	var setJSONTop = function setJSONTop() {
		if ($('.js-top-news').length) {
			// ティッカー用
			var setTicker = function setTicker() {
				// const cutTickerText = (_cnt) => {
				// 	$('.index-ticker-item').find('.index-ticker-title-text').each((idx, ele) => {
				// 		var rawText = $(ele).text();
				// 		if(rawText.length > _cnt) {
				// 			$(ele).text(rawText.substring(0, _cnt) + '...');
				// 		}
				// 	});
				// };
				// if($(window).width() >= 1024) {
				// 	cutTickerText(40);
				// } else if($(window).width() < 1024 && $(window).width() >= 768) {
				// 	cutTickerText(30);
				// } else if($(window).width() < 768) {
				// 	cutTickerText(5);
				// }
				var $setElm = $('.js-ticker ul');
				$setElm.slick({
					autoplay: true,
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplaySpeed: 5000,
					loop: true,
					speed: 1000,
					arrows: false,
					dots: false
				});
				setTimeout(function () {
					$('.js-ticker').addClass('is-active');
				}, 100);
			};
			getJSONcmsTop().done(function (cms) {
				getJSONmpocket().done(function (mpocket) {
					// リストをマージ
					margeList = itemListMarge(cms, mpocket);
					// html出力
					boxListHtml(itemListDateChange(margeList), 8, $('.js-top-news'));
					tickerHtml(itemListDateChange(margeList), 8, $('.js-top-ticker'));
					setTimeout(function () {
						setTicker();
					}, 500);
				});
			});
		}
	};

	// ニューストップ・ニュースIR部分
	var setJSONNews = function setJSONNews() {
		if ($('.js-news-list').length) {
			if (location.pathname.split('/')[4] === 'ir' || location.pathname.split('/')[4] === '') {
				$('.js-news-list').empty();
				var yearCategory = location.pathname.split('/')[3];
				if (location.pathname.split('/')[4] === 'ir') {
					yearCategory = location.pathname.split('/')[3] + '/ir';
				}
				if (location.pathname.split('/')[3] > 2018) {
					getJSONcmsNews(yearCategory).done(function (cms) {
						getJSONmpocketNews(location.pathname.split('/')[3]).done(function (mpocket) {
							// リストをマージ
							margeList = itemListMarge(cms, mpocket);
							// html出力
							boxListHtml(itemListDateChange(margeList), margeList.length, $('.js-news-list'));
						});
					});
				} else {
					getJSONcmsNews(yearCategory).done(function (cms) {
						// html出力
						textListHtml(itemListDateChange(cmsDataMolding(cms)), itemListDateChange(cmsDataMolding(cms)).length, $('.js-news-list'));
					});
				}
				setTimeout(function () {
					$('.js-news-list').addClass(a);
				}, 100);
			} else {
				// 通常のニュースリスト表示
				$('.js-news-list').addClass(a);
			}
		}
	};

	// irトップ（お知らせ／適時開示情報・最新決算情報）部分
	var setJSONIr = function setJSONIr() {
		if ($('.js-ir-news').length) {
			getJSONcmsIr().done(function (cms) {
				getJSONmpocket().done(function (mpocket) {
					// リストをマージ
					margeList = itemListMarge(cms, mpocket);
					// html出力
					textListHtml(itemListDateChange(margeList), 5, $('.js-ir-news'));
				});
			});
		}
	};
	setJSONTop();
	setJSONNews();
	setJSONIr();
})(window.jQuery, window.DIC);